<template>
    <div class="dating-no-manner">
        <StackRouterHeaderBar />
        <main class="main">
            <FeedbackTop class="m-b-40" :title="'왜 매너가 없다고<br />느끼셨나요?'" />
            <FeedbackOptionItems class="m-b-12" v-model="items" :multiSelect="true" />

            <transition name="slide-down">
                <div v-if="showDetailReason" class="detail-reason">
                    <p class="desc">기타 사유를 직접 입력해주세요</p>
                    <TextareaWithX :placeholder="'내용을 입력해주세요'" v-model="detailReason" />
                </div>
            </transition>
        </main>

        <footer class="footer">
            <button class="btn btn-primary" v-html="$translate('SUBMIT')" :disabled="disabled" @click="onClickNext" />
        </footer>
    </div>
</template>

<script>
import FeedbackTop from './components/FeedbackTopComponent'
import FeedbackOptionItems from './components/FeedbackOptionItems'
import datingService from '@/services/dating'

export default {
    name: 'DatingNoMannerPage',
    components: { FeedbackTop, FeedbackOptionItems },
    props: {
        user: Object,
    },
    data: () => ({
        detailReason: '',
        items: [],
    }),
    computed: {
        disabled() {
            return this.items.every(item => !item.selected)
        },
        showDetailReason() {
            return this.items[8].selected
        },
    },
    methods: {
        async init() {
            try {
                const options = await this.$store.dispatch('filterFeedbackOptionByCategory', 'manner_reason')
                this.items = options.map(option => {
                    this.$set(option, 'selected', false)
                    return option
                })
            } catch (e) {}
        },
        onClickNext() {
            const payload = this.preparedPayload()
            this.$store.commit('setSelectedFeedbacks', { category: 'noManner', feedbacks: payload })

            this.submit()
        },
        preparedPayload() {
            return this.items
                .filter(item => item.selected)
                .map(item => {
                    if (item.title === '기타') {
                        return { id: item.id, detail: this.detailReason }
                    } else {
                        return { id: item.id }
                    }
                })
        },
        async submit() {
            try {
                this.$loading(true)
                const { dating, payload } = await this.$store.dispatch('prepareFeedbackPayload')
                await datingService.feedback(dating.id, payload)
                await this.$store.dispatch('loadDatingSuccess')
                this.$toast.success('COMPLETE_SUBMIT')
                this.$stackRouter.clear()
            } catch (e) {
                this.$toast.error(e.msg)
            } finally {
                this.$loading(false)
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.dating-no-manner {
    .main {
        padding: 0 16px;
        overflow-y: auto;
        height: calc(100vh - 80px - 56px);
    }

    .detail-reason {
        .desc {
            margin-bottom: 8px;
            font-size: 12px;
            color: $grey-06;

            @include f-medium;
        }

        .textarea-with-x {
            height: 160px;
        }

        ::v-deep textarea {
            font-size: 14px;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        padding: 16px 20px;
        width: 100vw;
        border-top: solid 1px $grey-02;

        .btn {
            width: 100%;
            height: 48px;
        }
    }
}
</style>
